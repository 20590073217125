/**
* Constants
*/

$break_1_max: 767px;
$break_1_min: 768px;

$break_2_max: 1023px;
$break_2_min: 1024px;

$break_3_max: 1199px;
$break_3_min: 1200px;


@mixin background-size($size) {
  -webkit-background-size: $size;
  -moz-background-size: $size;
  -o-background-size: $size;
  background-size: $size;
}

@mixin background-attachment($attachment) {
  -webkit-background-attachment: $attachment;
  -moz-background-attachment: $attachment;
  -o-background-attachment: $attachment;
  background-attachment: $attachment;
}

@mixin background-position($position) {
  -webkit-background-position: $position;
  -moz-background-position: $position;
  -o-background-position: $position;
  background-position: $position;
}

@mixin background-repeat($repeat) {
  -webkit-background-repeat: $repeat;
  -moz-background-repeat: $repeat;
  -o-background-repeat: $repeat;
  background-repeat: $repeat;
}

@mixin css-gradient($from: #dfdfdf, $to: #f8f8f8) {
  background-color: $from;
  background-image: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
  background-image: -webkit-linear-gradient(top, $from, $to);
  background-image: -moz-linear-gradient(top, $from, $to);
  background-image: -o-linear-gradient(top, $from, $to);
  background-image: linear-gradient(to bottom, $from, $to);
}

@mixin bar($color) {
  background-color:$color;
  @include css-gradient( $color, darken($color, 20%) ); 
}