@import "global.scss";

html {
    position: relative;
    min-height: 100%;
}

$footer_height: 50px;

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;

    /* Set the fixed height of the footer here */
    height: $footer_height;
    background-color: #ffffff;
    padding: 8px;
    z-index: 100;
    line-height: $footer-height - 15px;
}

secure-email::after {
    content: attr(data-domain);
}

secure-email::before {
    content: attr(data-user) "@";
}

#background_cycler {
    position: fixed;
    width: 100%;
    height: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0);
    z-index: -1;

    .img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        .active {
            z-index: 3;
        }
    }
}

.be_main {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 4;

    .container {
        padding-bottom: $footer_height;
    }
}

.be_navigationbar {
    width: 100%;
    padding: 0;
    margin: 0;

    .container-fluid {
        padding: 0;

        .navbar-header {
            padding: 0 10px;
        }
    }

    .be_navbar {
        width: 100%;
        padding: 0;
        margin: 0;

        ul {
            width: 100%;
            padding: 0;
            margin: 0;

            li {
                width: calc(100% / 7);
                text-align: center;
                border-right: 1px solid darkgray;
            }

            li:last-child {
                border-right: 0px solid darkgray;
            }

            a {
                font-family: 'Raleway', sans-serif;
            }
        }
    }
}

@media (max-width: $break_1_min) {
    .be_navigationbar .be_navbar ul li {
        width: 100%;
    }
}

.page-title {
    width: 100%;
    padding: 2em 0 3em 0;

    .title_left {
        width: 45%;
        float: left;
        display: block;

        h1 {
            margin: 0;
        }
    }

    .title_right {
        width: 55%;
        float: right;
        display: block;
    }
}

.be_logo {
    background: rgba(255, 255, 255, 0.6);
    padding-top: 6em;
    padding-bottom: 2em;
    margin-bottom: 4em;
    height: 23em;
}

.be_contentbox {
    background: rgba(255, 255, 255, 0.8);
    min-height: calc(100vh - 27em - 50px);
    padding-bottom: 1em;
}

.be_content_nl {
    background: rgba(255, 255, 255, 0.8);
    min-height: 2em;
    margin-top: 1em;
    padding: 1em 0;
}

.be_gb_post {
    margin: 2em 0 2em 0;

    .be_gb_date {
        font-size: 12px;
        color: #3b3b3b;
    }

    .be_gb_content {
        padding-top: 0.1em;
        font-size: 15px;
    }
}

.be_tripreport {
    margin: 2em 0 2em 0;

    .be_tr_date {
        font-size: 12px;
        color: #3b3b3b;
    }

    .be_tr_content {
        padding-top: 0.1em;
        font-size: 15px;
    }
}

.be_video_post {
    margin: 2em 0 2em 0;

    .be_video_content {
        padding-top: 0.1em;
        font-size: 15px;
    }
}

// AudioJS

#volume-container {
    width: 180px;
}

#volume-slider {
    display: inline;
    width: 120px;
}

#volume-slider {
    vertical-align: center;
}

.audiojs {
    float: left !important;
}

#extended-controls-pre,
#extended-controls-post {
    float: left;
    background-color: #404040;
    height: 36px;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #444), color-stop(0.5, #555), color-stop(0.51, #444), color-stop(1, #444));
    background-image: -moz-linear-gradient(center top, #444 0%, #555 50%, #444 51%, #444 100%);
}

#extended-controls-pre {
    padding-left: 0.5em;
    padding-right: 0.25em;
}

#extended-controls-post {
    padding: 0 0.5em;
}

#extended-controls-pre .glyphicon,
#extended-controls-post .glyphicon {
    color: white;
    padding: 10px 3px;
}

p.play,
p.pause {
    margin: 5px 0;
    background: none !important;
}

p.loading,
p.error {
    margin-left: -4px;
}

p.pause {
    margin-left: -2px;
}

p.play:after {
    color: white;
    font-family: "Glyphicons Halflings";
    content: "\e072";
}

p.pause:after {
    color: white;
    padding: 10px 3px;
    font-family: "Glyphicons Halflings";
    content: "\e073";
}

#playlist a {
    color: #333333;
}

#playlist {
    margin: 0 3em;
}

#playlist-container {
    width: 675px;
    background-color: #f9f9f9;
    padding: 2em 3em;
    display: none;
}

#playlist-info-container {
    font-style: italic;
    margin-bottom: 1em;
}

#playlist-info-container span {
    font-weight: bold;
}

ol {
    padding: 0px;
    margin: 0px;
    list-style: decimal-leading-zero inside;
    color: #aaa;
    width: 460px;
    border-top: 1px solid #aaa;
    font-size: 0.9em;
}

ol li {
    position: relative;
    margin: 0px;
    padding: 9px 2px 10px;
    border-bottom: 1px solid #aaa;
    cursor: pointer;
}

ol li a {
    display: inline;
    text-indent: -3.3ex;
    padding: 0px 0px 0px 20px;
}

li.playing {
    color: #999;
    text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.3);
}

li.playing a {
    color: #777;
    font-weight: bold;
    font-size: 1.2em;
}

li.playing:before {
    font-family: "Glyphicons Halflings";
    content: "\e002";
    vertical-align: center;
    width: 14px;
    height: 14px;
    padding: 0px;
    line-height: 14px;
    margin: 0px;
    position: absolute;
    left: -28px;
    top: 13px;
    color: #999;
    font-size: 13px;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
}

span.track-duration {
    display: block;
    float: right;
    margin: 0 2em;
}

/* below is all for the ugly default range element */
/* this enhancement is modfied from http://codepen.io/aronwoost/pen/nlyrf */
input[type="range"] {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    width: 100%;
    height: 10px;
    margin: 0 0.5em;
    border: none;
    padding: 1px 2px;
    border-radius: 14px;
    background: #232528;
    box-shadow: inset 0 1px 0 0 #0d0e0f, inset 0 -1px 0 0 #3a3d42;
    -webkit-box-shadow: inset 0 1px 0 0 #0d0e0f, inset 0 -1px 0 0 #3a3d42;
    outline: none;

    /* no focus outline */
}

input[type="range"]::-moz-range-track {
    border: inherit;
    background: transparent;
}

input[type="range"]::-ms-track {
    border: inherit;
    color: transparent;

    /* don't drawn vertical reference line */
    background: transparent;
}

input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
    background: transparent;
}

input[type="range"]::-ms-tooltip {
    display: none;
}

/* thumb */

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 40px;
    height: 14px;
    border: none;
    border-radius: 12px;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #529de1), color-stop(100%, #245e8f));

    /* android <= 2.2 */
    background-image: -webkit-linear-gradient(top, #529de1 0, #245e8f 100%);

    /* older mobile safari and android > 2.2 */
    background-image: linear-gradient(to bottom, #529de1 0, #245e8f 100%);

    /* W3C */
}

input[type="range"]::-moz-range-thumb {
    width: 40px;
    height: 18px;
    border: none;
    border-radius: 12px;
    background-image: linear-gradient(to bottom, #529de1 0, #245e8f 100%);

    /* W3C */
}

input[type="range"]::-ms-thumb {
    width: 40px;
    height: 18px;
    border-radius: 12px;
    border: 0;
    background-image: linear-gradient(to bottom, #529de1 0, #245e8f 100%);

    /* W3C */
}